var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_module_recycle_bin_container_page d-flex flex-column",
    },
    [
      _c("header-bar", {
        staticClass: "header_bar bbox",
        on: { handleSearch: _vm.handleSearch },
      }),
      _c("main-page", { ref: "main", staticClass: "main bbox" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }