var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_module_recycle_bin_header_bar_page d-flex align-center justify-space-between",
    },
    [
      _c("div", { staticClass: "info_group d-flex align-center un_sel" }, [
        _c("div", { staticClass: "hint" }, [
          _vm._v("任务被删除后将保留在此回收站中，最多可保留30天"),
        ]),
        _c(
          "div",
          {
            staticClass: "clear_all cp",
            on: {
              click: function ($event) {
                return _vm.clearAll()
              },
            },
          },
          [_vm._v("全部清空")]
        ),
      ]),
      _c("div", { staticClass: "search_group d-flex align-center" }, [
        _c("div", { staticClass: "icon" }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          staticClass: "search bbox",
          attrs: { type: "text", placeholder: "搜索" },
          domProps: { value: _vm.search },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.doSearch($event)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search = $event.target.value
            },
          },
        }),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.search !== "",
              expression: "search !== ''",
            },
          ],
          staticClass: "clear cp",
          on: {
            click: function ($event) {
              return _vm.doClear()
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }