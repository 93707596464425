<template>
  <div
    class="components_module_recycle_bin_header_bar_page d-flex align-center justify-space-between"
  >
    <div class="info_group d-flex align-center un_sel">
      <div class="hint">任务被删除后将保留在此回收站中，最多可保留30天</div>
      <div class="clear_all cp"
        @click="clearAll();"
      >全部清空</div>
    </div>
    <div class="search_group d-flex align-center">
      <div class="icon"></div>
      <input type="text" class="search bbox"
        placeholder="搜索"
        v-model="search"
        @keydown.enter="doSearch($event);"
      >
      <div class="clear cp"
        v-show="search !== ''"
        @click="doClear()"
      ></div>
    </div>
    
  </div>
</template>
<script>
export default {
  data(){
    return {
      search: '',
    }
  },
  watch: {
    search: function(val){
      if(val == ''){
        this.$emit('handleSearch', '');
      }
    }
  },
  methods: {
    doClear(){
      this.search = '';
    },
    doSearch(e){
      const is_chinese = this.$api.handleKeydownChinese(e);
      if(is_chinese){
        return;
      }

      if(this.search.trim() != ''){
        this.$emit('handleSearch', this.search.trim());
      }
    },
    clearAll(){
      this.$api.recycle_bin.clearAll();
    }
  }
}
</script>
<style lang="scss" scoped>
.components_module_recycle_bin_header_bar_page{
  .hint{
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    padding-right: 10px;
  }
  .clear_all{
    font-size: 14px;
    color: $main_blue;
  }
  .search_group{
    background-color: #E9E9E9;
    position: relative;
    padding: 0 10px;
    height: 30px;
    border-radius: 4px;

    .icon{
      width: 14px;
      height: 14px;
      background-image: url(~@/assets/images/common/search_icon.png);
    }
    .search{
      @include placeholder(#999);
      border: none;
      outline: none;
      background-color: transparent;
      width: 200px;
      line-height: 29px;
      padding: {
        left:5px;
        right: 30px;
      }
      color: #333;
    }
    .clear{
      width: 14px;
      height: 14px;
      background-image: url(~@/assets/images/common/clear_icon.png);
      position: absolute;
      top:50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
}
</style>